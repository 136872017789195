import ResponsiveText36 from 'components/shared/atoms/ResponsiveText36/ResponsiveText36';
import FlexRow from '../../../shared/atoms/FlexRow/FlexRow';
import * as React from 'react';
import { styled } from '@mui/material/styles';

const InfoGrid = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 5.5rem;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledItem = styled('li')`
  font-size: 1.25rem;
  color: black;

  ::marker {
    color: #2a7576;
    font-size: 2.5rem;
  }
`;

const BulletList = styled('div')<{ $margin?: boolean }>`
  max-width: 40%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
    width: 100%;
    margin-bottom: ${({ $margin }) => ($margin ? '4rem' : '0')};
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const InforTitle = styled(ResponsiveText36)`
  color: black;
`;

const PerformerInfo = () => {
  return (
    <InfoGrid>
      <BulletList $margin>
        <InforTitle>About The Venue</InforTitle>
        <FlexRow>
          <ul>
            <StyledItem>3500 Sq. Ft. Stage</StyledItem>
            <StyledItem>Studio Lighting & Audio</StyledItem>
            <StyledItem>Professional 4k Cameras</StyledItem>
          </ul>
          <ul>
            <StyledItem>Green Screen</StyledItem>
            <StyledItem>Teleprompter</StyledItem>
            <StyledItem>Street-Level Load-In</StyledItem>
          </ul>
        </FlexRow>
      </BulletList>
      <BulletList>
        <InforTitle>What You Get</InforTitle>
        <FlexRow>
          <ul>
            <StyledItem>White-Glove Service</StyledItem>
            <StyledItem>Broadcast Quality Livestream</StyledItem>
            <StyledItem>Master Of Your Performance</StyledItem>
          </ul>
          <ul>
            <StyledItem>Marketing Studio</StyledItem>
            <StyledItem>Instant NFT Creation</StyledItem>
            <StyledItem>Marketplace Profit Share</StyledItem>
          </ul>
        </FlexRow>
      </BulletList>
    </InfoGrid>
  );
};

export default PerformerInfo;
