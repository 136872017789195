import { gql } from '@apollo/client';

export const fileUploadFieldsFragment = gql`
  fragment FileUploadFieldsFragment on ImageUpload {
    filename
    fileSize
    fileType
    url
  }
`;
