import React, { MouseEventHandler, useState } from 'react';
import { css, styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Fade from 'react-reveal/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import SEO from 'components/SEO/SEO';
import {
  LiveBashButton,
  Red100ContentHeader,
  ResponsiveText22,
  ResponsiveText46,
  ResponsiveText64,
  ResponsiveText74,
} from 'components/shared';
import ResponsiveText20 from 'components/shared/atoms/ResponsiveText20/ResponsiveText20';
import ResponsiveText24 from 'components/shared/atoms/ResponsiveText24/ResponsiveText24';
import InfoSection from 'components/PerformerPage/components/PerformerInfo/PerformerInfo';
import MailchimpForm from 'components/shared/molecules/MailchimpForm/MailchimpForm';
import FormModal from 'components/MarketingSets/FormModal';
import background from 'images/performer/performer-background.webp';
import backgroundMobile from 'images/performer/performer-background-mobile.png';
import img1 from 'images/home/home1.png';
import img2 from 'images/home/home2.png';
import img3 from 'images/home/home3.png';
import img4 from 'images/home/home4.png';
import hero from 'images/performer/performer-hero.png';
import la from 'images/performer/los-angeles.png';
import chi from 'images/performer/chicago.png';
import CalendlyForm from 'components/MarketingSets/CalendlyForm/CalendlyForm';
import { VariantSet } from 'components/SharedFocus/SharedFocus';
import { LANDING_PAGE_FOCUS } from 'utils/constants';

const PageWrapper = styled('div')`
  position: relative;
  background: url(${background}) no-repeat center;
  background-size: cover;
  color: white;
  padding: 9rem 5.125rem 5.125rem 5.125rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 9rem 3.375rem 3.375rem 3.375rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    background: url(${backgroundMobile}) no-repeat center top;
    padding: 21rem 1.5rem 3.375rem;
  }
`;

const CustomGrid = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5.5rem;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const HeroTitle = styled(Red100ContentHeader)`
  margin-bottom: 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.cyan50};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
    font-size: 1rem;
  }
`;

const Img = styled('img')`
  max-width: 90%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
    margin-bottom: 3rem;
  }
`;

const ImgNoMargin = styled('img')`
  max-width: 90%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`;

const ContentWrapper = styled('div')`
  background: url('${hero}') no-repeat center;
  background-size: cover;
  color: white;
  padding: 9rem 5.125rem 5.125rem 5.125rem;
  display: flex;
  justify-content: start;
  flex-direction: column;
  min-height: 47rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 9rem 1.5rem 3.375rem 1.5rem;
    align-items: center;
    min-height: 52rem;
  }
`;

const LocationTitle = styled(ResponsiveText46)`
  text-align: center;
  font-size: 2.5625rem !important;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.75rem !important;
  }
`;

const LocationText = styled(ResponsiveText46)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.75rem !important;
  }
`;

const HeroButton = styled(LiveBashButton)`
  color: black !important;
  width: 252px;
  height: 55px;
  border-radius: 33px;
  font-weight: 700;
  font-size: 18px;
`;

const StyledWrapper = styled('div')`
  width: 62%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const LocationContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
`;

const HeroText = styled(ResponsiveText22)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const FormWrapper = styled('div')`
  max-width: 45%;

  ${({ theme }) => theme.breakpoints.down(700)} {
    max-width: 100%;
  }
`;

const TitleText = styled(ResponsiveText46)`
  text-align: center;
`;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 33%;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 3rem;
    width: 100%;
  }
`;

const PositionedMailchimpForm = styled('div')`
  max-width: 90vw;
  position: absolute;
  top: -5.5rem;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: -16rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

type SectionContentProps = {
  title: string;
  text: string;
  button?: boolean;
  buttonText?: string;
  largeTitle?: boolean;
  largeText?: boolean;
  smallText?: boolean;
  buttonCallback?: MouseEventHandler<HTMLButtonElement>;
};

const liveBashString = 'Live Bash'.replace(/ /g, '\u00A0');

const sectionText = [
  {
    title: `We're Building Stages For Livestream Performances`,
    text: 'Easily reserve Stagetime, Livestream Your Show, and Sell Digital Collectibles of Your Performance.',
    largeTitle: true,
    largeText: true,
  },
  {
    title: 'Easily Book Stagetime',
    text: 'Our direct-to-venue booking system allows you to choose a date and time to reserve the stage.',
    largeText: true,
    button: true,
    buttonText: 'Learn More',
  },
  {
    title: 'Plan Your Show',
    text: 'Work with our production and marketing teams to plan your show and get the word out.',
  },
  {
    title: `Livestream @ ${liveBashString}`,
    text: 'Shows will be streamed on our platform and performer’s preferred social channels.',
  },
  {
    title: 'Mint Your Moments',
    text: 'Performers create NFTs with the literal push of a button live on stage.',
  },
];

// eslint-disable-next-line react/prop-types
const SectionContent = ({
  title,
  text,
  largeTitle,
  largeText,
  smallText,
  button,
  buttonText,
  buttonCallback,
}: SectionContentProps) => {
  return (
    <FormWrapper>
      {largeTitle ? <ResponsiveText74>{title}</ResponsiveText74> : <ResponsiveText46>{title}</ResponsiveText46>}
      {largeText ? (
        <ResponsiveText24>{text}</ResponsiveText24>
      ) : smallText ? (
        <ResponsiveText20>{text}</ResponsiveText20>
      ) : (
        <ResponsiveText22>{text}</ResponsiveText22>
      )}
      {button ? (
        <HeroButton className='ripple' onClick={buttonCallback}>
          <span>{buttonText}</span>
        </HeroButton>
      ) : null}
    </FormWrapper>
  );
};

const PerformerLoggedOut: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [formModalOpen, setFormModalOpen] = useState(false);

  return (
    <>
      <FormModal open={formModalOpen} setOpen={setFormModalOpen}>
        <CalendlyForm
          calendarId='calendar-desktop-widget'
          focusSet={VariantSet.CalendlyForm}
          focusType={LANDING_PAGE_FOCUS.General}
          inModal
        />
      </FormModal>
      <SEO title='Stages For Livestream Performances' />
      <ContentWrapper>
        <Container>
          <StyledWrapper>
            <Fade bottom>
              <HeroTitle>Calling all performers</HeroTitle>
              <ResponsiveText64 sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                Be The First <br /> On Our Stage
              </ResponsiveText64>
              <HeroText>
                Be among the first to reserve time on our Chicago stage, livestream your performance, and sell NFTs
                directly to your fans.
              </HeroText>
              <HeroButton className='ripple' onClick={() => setFormModalOpen(true)}>
                <span>Book a Consultation</span>
              </HeroButton>
            </Fade>
          </StyledWrapper>
        </Container>
      </ContentWrapper>
      <PageWrapper>
        <PositionedMailchimpForm>
          <MailchimpForm skinny={!isSmallScreen} round={isSmallScreen} />
        </PositionedMailchimpForm>
        <Container>
          <InfoSection />
          <TitleText>How Live Bash Works</TitleText>

          <CustomGrid>
            <Fade left>
              <Img src={img1} alt={'Cell Phone Display of LiveBash Booking Calendar'} />
            </Fade>
            {/*<div>*/}
            <SectionContent {...sectionText[1]} buttonCallback={() => setFormModalOpen(true)} />
            {/*</div>*/}
          </CustomGrid>
          <CustomGrid>
            {isSmallScreen ? (
              <>
                <Fade right>
                  <Img src={img2} alt={'Blue check mark'} />
                </Fade>
                <SectionContent {...sectionText[2]} />
              </>
            ) : (
              <>
                <SectionContent {...sectionText[2]} />
                <Fade right>
                  <Img src={img2} alt={'Blue check mark'} />
                </Fade>
              </>
            )}
          </CustomGrid>
          <CustomGrid>
            <Fade left>
              <Img src={img3} alt={'Laptop display of concert livestream'} />
            </Fade>
            <SectionContent {...sectionText[3]} />
          </CustomGrid>
          <CustomGrid>
            {isSmallScreen ? (
              <>
                <Fade right>
                  <Img src={img4} alt={'Two tablets displaying concert livestream'} />
                </Fade>
                <SectionContent {...sectionText[4]} />
              </>
            ) : (
              <>
                <SectionContent {...sectionText[4]} />
                <Fade right>
                  <Img src={img4} alt={'Two tablets displaying concert livestream'} />
                </Fade>
              </>
            )}
          </CustomGrid>
          <LocationContainer>
            <StyledContainer>
              <LocationText>Locations</LocationText>
            </StyledContainer>
            <StyledContainer>
              <LocationTitle>LA</LocationTitle>
              <ImgNoMargin src={la} alt='Los Angeles' />
            </StyledContainer>
            <StyledContainer>
              <LocationTitle>CHI</LocationTitle>
              <ImgNoMargin src={chi} alt='Chicago' />
            </StyledContainer>
          </LocationContainer>
        </Container>
      </PageWrapper>
    </>
  );
};

export default PerformerLoggedOut;
