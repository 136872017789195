import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import LiveBashButton from 'components/shared/atoms/LiveBashButton/LiveBashButton';
import useMutationConsultationMailchimp from 'hooks/useMutationConsultationMailchimp';
import { FormAlert } from '../../../MarketingSets/SharedStyles';

const FormContainer = styled('form')<{ $round?: boolean; $skinny?: boolean }>`
  background-color: white;
  display: flex;
  flex-direction: ${({ $skinny }) => ($skinny ? 'row' : 'column')};
  max-width: 100%;
  padding: ${({ $round, $skinny }) => ($round ? '8rem 3.5rem 3.5rem' : $skinny ? '1.25rem 2.5rem' : '2.5rem')};
  border-radius: ${({ $round, $skinny }) => ($round ? '14rem' : $skinny ? '10rem' : '2rem')};
  align-items: center;
  justify-content: ${({ $skinny }) => ($skinny ? 'space-between' : 'start')};
`;

const Text = styled('p')<{ $skinny?: boolean }>`
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 128%;
  color: ${({ theme, $skinny }) => ($skinny ? 'white' : theme.colors.grayscale700)};
  margin-bottom: ${({ $skinny }) => ($skinny ? '1rem' : '2rem')};
  margin-top: 0;
  max-width: 80%;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down(384)} {
    font-size: 1.2rem;
  }
`;

type MailchimpFormProps = {
  skinny?: boolean;
  round?: boolean;
  header?: React.ReactNode;
  button?: React.ReactNode;
};

const MailchimpForm: React.FC<MailchimpFormProps> = ({ skinny, round, header, button }) => {
  const { consultationMailchimp, mutationResults } = useMutationConsultationMailchimp();
  const { loading, error } = mutationResults;
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [resultMessage, setResultMessage] = useState<string | undefined>();
  const headerText = header
    ? header
    : skinny || round
    ? 'Join the waitlist to perform.'
    : 'Join the waitlist to perform on our stages...';
  const buttonText = button ? button : skinny || round ? 'Submit' : 'Get On Stage';

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      setIsSuccess(false);
      setResultMessage(undefined);

      (async () => {
        try {
          const result = await consultationMailchimp({
            variables: {
              input: {
                name,
                email,
                tags: ['Fan', 'Beta'],
              },
            },
          });

          if (result.data?.consultationMailchimp?.success) {
            setIsSuccess(true);
            setResultMessage(`Success! You're on the list.`);
            setEmail('');
            setName('');
          } else {
            setIsSuccess(false);
            setResultMessage('There was an issue with your submission. Please try again later.');
          }
        } catch (err) {
          console.error(err);
        }
      })();
    },
    [consultationMailchimp, email, name]
  );

  if (error) {
    console.error(error);
  }

  return (
    <>
      {skinny && <Text $skinny={skinny}>{headerText}</Text>}
      <FormContainer onSubmit={handleSubmit} $round={round} $skinny={skinny}>
        {!skinny && <Text $skinny={skinny}>{headerText}</Text>}
        {resultMessage && (
          <FormAlert
            severity={isSuccess ? 'success' : 'warning'}
            variant='outlined'
            sx={{ marginTop: 0, marginBottom: '1rem' }}
          >
            {resultMessage}
          </FormAlert>
        )}
        <TextField
          sx={{ margin: skinny ? '0 2rem 0 0' : '0 0 2rem 0', width: skinny ? '23.5rem' : '100%' }}
          label='Name'
          variant='outlined'
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          sx={{ margin: skinny ? '0 2rem 0 0' : '0 0 2rem 0', width: skinny ? '23.5rem' : '100%' }}
          label='Email'
          variant='outlined'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <LiveBashButton $round={round || skinny} disabled={loading} type='submit' className='ripple'>
          <span>{buttonText}</span>
        </LiveBashButton>
      </FormContainer>
    </>
  );
};

export default MailchimpForm;
