import { gql } from '@apollo/client';
import { stageFieldsFragment } from '../studios/stageFragments';
import { fileUploadFieldsFragment } from '../uploads/fileUploadFragments';

export const performanceFieldsFragment = gql`
  fragment PerformanceFieldsFragment on Performance {
    id
    creatorId
    marketplaceId
    stage {
      ...StageFieldsFragment
    }
    startTimestamp
    endTimestamp
    setupStartTimestamp
    teardownEndTimestamp
    performanceLength
    performanceType {
      id
      name
      category {
        id
        name
      }
    }
    title
    description
    thumbnailUrl
    streamUrl
    contentUrl
    duration
    labels
    externalBooking {
      id
      source
    }
    requireFullPaymentAtBooking
    isRemainderPaid
    depositAmountInCents
    totalAmountInCents
    timeslotPricing {
      timeslotDescription
      startDateTime
      endDateTime
      amountInCents
      durationMinutes
      hourlyRateInCents
      isTimeslotRate
    }
    performancePlanning {
      techRequests {
        requestedEquipment {
          checked
          text
          id
        }
        performerEquipment {
          checked
          text
          id
        }
        otherTechRequests
      }
      marketingMaterials {
        logo {
          ...FileUploadFieldsFragment
        }
        postersSocialMediaAssets {
          ...FileUploadFieldsFragment
        }
        website
        otherMarketingNotes
        socialMedia {
          social
          account
        }
      }
    }
  }

  ${stageFieldsFragment}
  ${fileUploadFieldsFragment}
`;
