import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useEffect } from 'react';
import { stageFieldsFragment } from '../gql/fragments/studios/stageFragments';
import { PerformanceData } from '@livebash/common-lib';
import { fileUploadFieldsFragment } from '../gql/fragments/uploads/fileUploadFragments';
import { performanceFieldsFragment } from '../gql/fragments/performances/performanceFragments';

const GET_MY_PERFORMANCES = gql`
  query GetMyPerformances {
    myPerformances {
      ...PerformanceFieldsFragment
    }
  }

  ${performanceFieldsFragment}
  ${stageFieldsFragment}
  ${fileUploadFieldsFragment}
`;

export interface MyPerformancesResult {
  myPerformances: PerformanceData[];
}

export default function useQueryMyPerformances(
  options?: QueryHookOptions<MyPerformancesResult>
): QueryResult<MyPerformancesResult> {
  const {
    state: { authToken },
  } = useAuthContext();

  const { refetch, ...result } = useQuery<MyPerformancesResult>(GET_MY_PERFORMANCES, {
    ...(options || {}),
    skip: !authToken,
  });

  // Reload the performances if the authToken changes.
  useEffect(() => {
    (async () => {
      if (authToken) {
        await refetch();
      }
    })();
  }, [authToken, refetch]);

  return {
    ...result,
    refetch,
  };
}
