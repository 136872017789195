import { Image, MarketingMaterials, PerformanceData, Stage, TechRequests } from '@livebash/common-lib';
import { STAGE_CHICAGO_ID, STAGE_LOS_ANGELES_ID } from '../data/stages';
import imgDefaultChicago from 'images/performances/stageDefault.webp';
import imgDefaultLA from 'images/performances/stage-default-la.webp';
import { getDateTime } from './dateUtils';
import { DateTime } from 'luxon';

export type UpcomingAndPastPerformances = {
  upcoming: PerformanceData[];
  past: PerformanceData[];
};

class PerformanceUtils {
  static defaultImages: Record<string, Image> = {
    [STAGE_CHICAGO_ID]: { url: imgDefaultChicago, alt: 'Cloud Gate in Chicago, IL' },
    [STAGE_LOS_ANGELES_ID]: { url: imgDefaultLA, alt: 'Hollywood sign in Los Angeles, CA' },
  };

  static getCoverImage(booking: PerformanceData): Image {
    return booking.coverImage || PerformanceUtils.getDefaultForStage(booking.stage);
  }

  static isUpcoming(booking: PerformanceData): boolean {
    const dt = getDateTime(booking.startTimestamp);

    return DateTime.now() <= dt;
  }

  static getDefaultForStage(stage: Stage): Image {
    return PerformanceUtils.defaultImages[stage.id] || imgDefaultChicago;
  }

  static splitUpcomingAndPastPerformances(performances: PerformanceData[]): UpcomingAndPastPerformances {
    const now = DateTime.now();

    return performances.reduce<UpcomingAndPastPerformances>(
      (split, performance) => {
        const key = getDateTime(performance.startTimestamp) >= now ? 'upcoming' : 'past';
        split[key].push(performance);
        return split;
      },
      { upcoming: [], past: [] }
    );
  }

  static getNextPerformance(performances: PerformanceData[]): PerformanceData | undefined {
    const now = DateTime.now();

    return PerformanceUtils.sortOldestToNewest(performances).find((x) => getDateTime(x.startTimestamp) > now);
  }

  static sortOldestToNewest(performances: PerformanceData[]): PerformanceData[] {
    return performances.slice().sort((a, b) => {
      return getDateTime(a.startTimestamp).toMillis() - getDateTime(b.startTimestamp).toMillis();
    });
  }

  static sortNewestToOldest(performances: PerformanceData[]): PerformanceData[] {
    return performances.slice().sort((a, b) => {
      return getDateTime(b.startTimestamp).toMillis() - getDateTime(a.startTimestamp).toMillis();
    });
  }

  static calculateStepsCompleted(
    techRequests: TechRequests | undefined,
    marketingMaterials: MarketingMaterials | undefined
  ): number {
    // Starting this count at 1 since if they've created the event that is step 1
    let stepsCompleted = 1;

    // Check if there are any of the sections filled out for the tech requests, if so add 1 to completed steps
    if (techRequests) {
      const { requestedEquipment, performerEquipment, socialMedia, otherTechRequests } = techRequests;

      // Adding this value for the future if further progress bars are desired for tech requests
      let techRequestsStepsCompleted = 0;

      // Check if there are any other tech requests
      if (otherTechRequests.length > 0) {
        techRequestsStepsCompleted += 1;
      }

      // Check if there is any equipment being brought
      const checkValuesPerformerEquipment = performerEquipment.filter((request) => request.checked);

      if (checkValuesPerformerEquipment.length > 0) {
        techRequestsStepsCompleted += 1;
      }

      // Check if there is any equipment being requested
      const checkValuesRequestedEquipment = requestedEquipment.filter((request) => request.checked);

      if (checkValuesRequestedEquipment.length > 0) {
        techRequestsStepsCompleted += 1;
      }

      // Check if any tech request steps were completed
      if (techRequestsStepsCompleted > 0) {
        stepsCompleted += 1;
      }
    }

    // Check if there are any of the sections filled out for the marketing materials, if so add 1 to completed steps
    if (marketingMaterials) {
      const { logo, otherMarketingNotes, postersSocialMediaAssets, website, socialMedia } = marketingMaterials;

      // Adding this value for the future if further progress bars are desired for marketing materials
      let marketingMaterialStepsCompleted = 0;

      // Check for null values with logo and website
      if (logo) {
        marketingMaterialStepsCompleted += 1;
      }

      if (website) {
        marketingMaterialStepsCompleted += 1;
      }

      // Check if the notes or assets arrays are empty
      if (otherMarketingNotes.length > 0) {
        marketingMaterialStepsCompleted += 1;
      }

      if (postersSocialMediaAssets.length > 0) {
        marketingMaterialStepsCompleted += 1;
      }

      // Check if any of the social media accounts have been set
      const checkValuesSocialMedia = socialMedia.filter((social) => social.account != '');

      if (checkValuesSocialMedia.length > 0) {
        marketingMaterialStepsCompleted += 1;
      }

      // Check if any marketing steps were completed
      if (marketingMaterialStepsCompleted > 0) {
        stepsCompleted += 1;
      }
    }

    return stepsCompleted;
  }
}

export default PerformanceUtils;
