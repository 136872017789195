import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PerformanceData } from '@livebash/common-lib';
import useQueryMyPerformances from 'hooks/useQueryMyPerformances';
import PerformanceUtils, { UpcomingAndPastPerformances } from 'utils/performanceUtils';

interface PerformerContextState {
  myPerformances?: PerformanceData[];
  upcomingAndPastPerformances: UpcomingAndPastPerformances;
  myPerformancesLoading: boolean;
  myPerformancesError?: Error;
  performanceToPlan?: string;
  savePerformanceToPlan?: (value: string | undefined) => void;
  nextPerformance?: PerformanceData;
  refetchMyPerformances: () => void;
  updateMyPerformances: (performances: PerformanceData[] | undefined) => void;
}

const initialState: PerformerContextState = {
  myPerformances: undefined,
  myPerformancesLoading: false,
  myPerformancesError: undefined,
  performanceToPlan: undefined,
  savePerformanceToPlan: undefined,
  upcomingAndPastPerformances: { upcoming: [], past: [] },
  nextPerformance: undefined,
  refetchMyPerformances: () => undefined,
  updateMyPerformances: () => undefined,
};

export const PerformerContext = createContext<PerformerContextState>(initialState);

interface PerformerProviderProps {
  children: React.ReactNode;
}

export function PerformerProvider({ children }: PerformerProviderProps) {
  const {
    data,
    loading: myPerformancesLoading,
    error: myPerformancesError,
    refetch: refetchMyPerformances,
  } = useQueryMyPerformances({ fetchPolicy: 'cache-and-network' });

  const [performanceToPlan, setPerformanceToPlan] = useState<string | undefined>(initialState.performanceToPlan);
  const [myPerformances, setMyPerformances] = useState<PerformanceData[] | undefined>(data?.myPerformances);

  // Adding this in case there is a future use of refetch it will update myPerformances
  useEffect(() => {
    setMyPerformances(data?.myPerformances);
  }, [data?.myPerformances]);

  const updateMyPerformances = useCallback((performances: PerformanceData[] | undefined) => {
    setMyPerformances(performances);
  }, []);

  const savePerformanceToPlan = useCallback((performanceId: string | undefined) => {
    setPerformanceToPlan(performanceId);
  }, []);

  const upcomingAndPastPerformances = useMemo(
    () => PerformanceUtils.splitUpcomingAndPastPerformances(myPerformances || []),
    [myPerformances]
  );

  const nextPerformance = useMemo(
    () => PerformanceUtils.getNextPerformance(upcomingAndPastPerformances.upcoming),
    [upcomingAndPastPerformances.upcoming]
  );

  const contextValue = useMemo<PerformerContextState>(
    () => ({
      myPerformances: data?.myPerformances,
      myPerformancesLoading,
      myPerformancesError,
      savePerformanceToPlan,
      performanceToPlan,
      upcomingAndPastPerformances,
      nextPerformance,
      refetchMyPerformances,
      updateMyPerformances,
    }),
    [
      data?.myPerformances,
      myPerformancesError,
      myPerformancesLoading,
      savePerformanceToPlan,
      performanceToPlan,
      nextPerformance,
      refetchMyPerformances,
      upcomingAndPastPerformances,
      updateMyPerformances,
    ]
  );

  return <PerformerContext.Provider value={contextValue}>{children}</PerformerContext.Provider>;
}

export function usePerformerContext() {
  const context = useContext(PerformerContext);
  if (!context) {
    throw new Error('usePerformerContext must be used within a PerformerProvider');
  }
  return context;
}
