import React from 'react';
import { PageProps } from 'gatsby';
import { scrollToTopOfMainSection } from 'utils/scrollUtils';
import { PerformerProvider } from 'context/PerformerContext/PerformerContext';
import MainContent from 'components/MainContent/MainContent';
import PerformerLoggedOut from 'components/PerformerPage/PerformerLoggedOut/PerformerLoggedOut';

const PerformerPageRoute = ({ location }: PageProps): JSX.Element => {
  React.useEffect(() => {
    scrollToTopOfMainSection();
  }, []);

  return (
    <MainContent>
      <PerformerProvider>
        <PerformerLoggedOut location={location} />
      </PerformerProvider>
    </MainContent>
  );
};

export default PerformerPageRoute;
