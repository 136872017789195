import { Stage } from '@livebash/common-lib';

export const CHICAGO_STAGE: Stage = {
  id: 'chicago',
  studio: {
    id: 'studio_chicago',
    location: {
      id: 'location_chicago',
      address: '1811 W Berteau Ave',
      city: 'Chicago',
      state: 'IL',
      country: 'USA',
      coordinates: {
        latitude: 41.82403785187707,
        longitude: -87.61944108792564,
      },
    },
    directionsLink: 'https://goo.gl/maps/UBv4KB9n1tkRVhH16',
  },
  name: 'Chicago',
  active: true,
  details: {
    fullName: 'Live Bash Chicago',
    depositAmount: 100,
    stageThumbnail: {
      url: '/images/stages/chicago/stage_chicago_thumbnail.webp',
      alt: 'Stage thumbnail',
    },
    carouselImages: [
      {
        url: '/images/stages/chicago/stage_chicago_1.webp',
        alt: 'Stage image 1',
      },
      {
        url: '/images/stages/chicago/stage_chicago_5.webp',
        alt: 'Stage image 2',
      },
      {
        url: '/images/stages/chicago/stage_chicago_6.webp',
        alt: 'Stage image 3',
      },
    ],
    aboutTheStage: [
      {
        header: undefined,
        listItems: [
          'Green Room',
          'Studio Lighting',
          'Not Available',
          'Street Level Load-In',
          'Broadcast Quality Audio',
          'Not Available',
        ],
      },
      {
        header: 'Cameras',
        listItems: ['Digital Collectible Sale Opportunities', 'Dedicated On-Site'],
      },
      {
        header: 'Production Team',
        listItems: ['Green Rooms', 'Greenscreen Capabilities'],
      },
    ],
  },
};

export const LOS_ANGELES_STAGE: Stage = {
  id: 'los_angeles',
  studio: undefined,
  name: 'Los Angeles (coming soon)',
  active: false,
  details: undefined,
};

export const STAGE_CHICAGO_ID = 'chicago';
export const STAGE_LOS_ANGELES_ID = 'los_angeles';
