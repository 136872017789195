import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const ResponsiveText24 = styled(Typography)`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 142%;
  margin: 2rem 0;
  font-family: 'Sora', sans-serif;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.2rem;
    margin: 1.33rem 0;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1rem;
    margin: 1rem 0;
  }
`;

export default ResponsiveText24;
