import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const ResponsiveText22 = styled(Typography)`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 180%;
  margin: 2rem 0;
  display: flex;
  align-items: flex-start;
  font-family: 'Sora', sans-serif;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 0.9rem;
    margin: 1.33rem 0;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1rem;
    margin: 1rem 0;
  }
`;

export default ResponsiveText22;
