import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const ResponsiveText36 = styled(Typography)`
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 228%;
  font-family: 'Sora', sans-serif;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.6rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1.2rem;
  }
`;

export default ResponsiveText36;
